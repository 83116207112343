@CHARSET "UTF-8";

div.sprint-form-generic-error{color:#d8111b !important;padding-bottom:10px;width:100%;font-size: 1.125rem;}
div.sprint-form-generic-info{color:#11d81b !important;padding-bottom:10px;width:100%}
.bigger-font125{font-size:125%}
.bigger-font150{font-size:150%}
.emergency-background{background-color:#e01515}
.tooltip {
	position: relative;
	text-decoration: underline;
	outline: 0;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 24rem;
	background-color: #757575;
	color: #fff;
	line-height:1.2;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	top: 150%;
	left: 50%;
	margin-left: -12rem;
	opacity: 0;
	transition: opacity 0.5s;
	text-transform: none;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #757575 transparent;
}

.tooltip:focus .tooltiptext, .tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

/* It will somehow make the select dropdown options looking nice (space between options in IE) ---- its "Segoe UI" font family */
form select{
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Sprint Sans Regular;
} 

.user-current-location{
	padding: 1rem 0;
	margin: 1.5rem 0;
	border: 1px solid #cecfcf;
}

.user-current-location select{
	margin-bottom: 0!important;
}

.sts-user-id{
	padding: 1rem 0;
	margin: 1.5rem 0;
	border: 1px solid #cecfcf;
}
.sts-user-id .sts-user-id-input{
	margin-bottom: 0;
}

.sts-user-id button{
	margin: 0.5rem 2.5rem;
}

.confirm-delete{
	font-weight: bold;
	color: #e20074;
}

.notes-confirm-delete{
	margin: .875rem;
	font-weight: bold;
	color: #e20074;
}

.notes-link-confirm{
	-ms-flex-direction: row!important;
	flex-direction: row!important;
}

.scrollable-div{
	max-height: 47rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.sprint-icon-large:before {
	font-size: 1.875rem;
}

.sprint-input{
	font-size: 1.125rem;
}

.login-left{
	max-width: 100%;
}

/* Style sheets for print page */
.printtext {
	margin: auto
}

.printtext .checkmark{
	max-width: .9rem;
	max-height: .9rem;
	width: auto;
	height: auto;
	padding-right: 5px;
}

.printtext .print-logo{
	max-width: 50%;
	max-height: 50%;
	width: auto;
	height: auto;
	margin-bottom: 15px;
}

.printtext table {
	margin-top: 3px !important;
}

tbody tr:nth-child(even){
	background-color: inherit!important;	
}

.printtext thead, .printtext tbody, .printtext tfoot{
	background-color: inherit!important;
	border: none!important;
}

.printtext tbody th, .printtext tbody td{
	padding: 1px 0 !important
}

.printtext tbody th{
	padding-bottom: 3px!important;
}

.printtext .heading {
	font-size: 1.5rem!important;
	padding-bottom: 3px!important;
}

.printtext .pageheading {
	font-size: 1.4rem!important;
	font-weight: bold!important;
}

.printtext .pageheading-date {
	font-size: 1.3rem!important;
	font-weight: bold!important;
}

.printtext .mt-3 {
	margin-top: 3px !important;
}

.printtext .text-ctr {
	text-align: center
}

.printtext .text-left {
	text-align: left
}

.printtext .text-right {
	text-align: right;
	padding-right: 15px;
}

.printtext .heading-extra{
	padding-top: 15px; 
	border-bottom: 1px solid #999999;
}

.printtext .no-padding{
	padding:0
}

.printtext .title-text{
	text-align: right; 
	padding-right: 15px; 
}

.printtext .padding-8{
	padding-top: 8px;
}

.printtext .topleft-padding{
	padding-top:8px;
	padding-left:30px
}

.printtext .text-phonenumber{
	border-bottom:1px dotted #CCCCCC
}

.call-status {
	font-family: inherit;
	font-size: 1.5rem;
	padding-bottom: 1.25rem;
}

.hr-bold {
	border: none;
	height: 2px;
	color: #000;
	background-color: #000;
	margin: 3rem auto;
}

.hr-light {
	border: none;
	height: 1px;
	color: #333;
	background-color: #333;
}

.txt-super {
	vertical-align: super;
	font-size: 60%;
}

.ms-clr-input::-ms-clear {
	display: none;
}

.std-txt-bigger {
	font-size: 120%;
	margin-top: 1rem;
}

.chkbx-align {
	margin: 0.6rem;
}

.impt-black {
	color: #000 !important;
}

.impt-0mb {
	margin-bottom: 0 !important;
}

.std-txt-80pct {
	font-size: 80%;
	margin: auto;
}

.std-txt-bold {
	font-weight: bold;
}

.std-txt-200pct {
	font-size: 200%;
}

.height-100pct {
	height: 100%;
}

.width-100pct {
	width: 100%;
}

.skip-links-cls a {
	position: absolute !important;
	left: -999em;
}

.skip-links-cls a:active, .skip-links-cls a:focus {
	position: absolute;
	left: 5px;
	top: 5px;
	padding: 20px;
	outline: 2px solid;
	text-decoration: none;
	background-color: white;
	z-index: 100000;
}

.m-20-a-0-a-ctr {
	margin: 20px auto 0 auto;
	text-align: center;
}

.no-display {
	display: none
}

.m-top-left-20 {
	margin-top: 20px;
	margin-left: 20px;
}

.bdr-1-bs {
	border: 1px solid black;
	word-wrap: break-word;
}

.agt-note-tbl {
	border-collapse: collapse;
	width: 100%;
	margin-top: 1rem;
	table-layout: fixed;
}

.width-10pct {
	width: 10%;
}

.width-11pct {
	width: 11%;
}

.width-12pct {
	width: 12%;
}

.width-16pct {
	width: 16%;
}

.width-18pct {
	width: 18%;
}

.width-20pct {
	width: 20%;
}

.add-ovrd-intv, .add-ovrd-intv:focus {
	resize: none;
	width: 100%;
	border: 1px solid black;
	padding: 8px;
}

.add-agt-note-ta, .add-agt-note-ta:focus {
	resize: none;
	width: 100%;
	height: 20rem;
	border: 1px solid black;
	margin-top: 1rem;
	padding: 8px;
}

.spinning_loader {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	width: 120px;
	height: 120px;
	margin: -76px 0 0 -76px;
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	animation: spinload 2s linear infinite;
}

@keyframes spinload {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.pls_wait_msg {
	padding-bottom: 32rem;
}

.flex_container {
	display: flex;
	justify-content: space-between;
}

.first_flex_item {
	flex: 1;
}

.red-txt {
	color: red;
}

.chk-txt {
	white-space: pre-wrap;
}
